body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  display: flex;
  flex-direction: column;
}

.list-landing {
margin: 0 auto;
}

.list-button {
box-sizing: border-box;
margin: 0;
padding: 16px 20px;
font-size: 14px;
font-weight: 500;
line-height: 20px;
vertical-align: middle;
background-repeat: repeat-x;
background-position: -1px -1px;
background-size: 110% 110%;
border: 1px solid rgba(27,31,35,.2);
border-radius: .5em;
color: #24292e;
background-color: #eff3f6;
background-image: linear-gradient(-180deg,#fafbfc,#eff3f6 90%);
width: 90%;
text-align: center;
top: 0!important;
margin-bottom: 8px;
max-width: 100%;
position: relative;
}
.card-container {
padding-top: 3em;
padding-right: 3em;
padding-left: 3em;
padding-bottom: 1em;
box-sizing: border-box;
display: block;
padding: 16px;
margin-top: -1px;
border-top: 1px solid #e1e4e8;
}

.list-container {
box-sizing: border-box;
background-color: #fff;
border: 1px solid #d1d5da;
border-radius: 3px;
}

.button-container{
width: 80%
}
.card-body {
font-size: 14px;
line-height: 1.5;
color: #24292e;
box-sizing: border-box;
margin-bottom: 16px;
}

.img-container {
list-style-type: none;
box-sizing: border-box;
margin-right: 16px;
margin-left: 16px
}

h1 {
font-size: 20px;
font-weight: 600;
line-height: 1.25;
}

h6 {
color: #586069;
font-size: 12px;
text-transform: uppercase;
margin-bottom: 4px;
}

.img-small {
max-width: 16px;
max-height: 16px;
margin: 0px .33em
}
.img-medium {
 width: 48px;
height: 48px;
border-style: none;
border-radius: 4px;
margin: 18px;
}

.logo {
line-height: 1.5;
color: #24292e;
text-align: center;
box-sizing: border-box;
margin: .67em 0;
margin-top: 10;
margin-bottom: 0;
font-weight: 500;
font-size: 40px;
}

.background {
background-color: #fafbfc;
}

.search-input {
box-sizing: border-box;
display: inline-flex;
line-height:18px;
vertical-align: middle;
box-shadow: rgba(69, 86, 103, 0.15) 0px 1px 2px inset;
width: 240px;
background-repeat: no-repeat;
background-position: right 8px center;
border-radius: 8px;
outline: none;
padding: 6px 8px;
color: rgb(75, 94, 172);
background-color: rgba(255, 255, 255, 0.67);
font-size: 16px;
border-color: transparent;
margin: 1em;
padding:1em;
} 

.link-hr {
display: none;
padding-bottom: 2em;
border: 0;
}

.toggle-button-1 {
box-sizing: border-box;
position: relative;
float: left;
padding: 4px 12px;
font-weight: 500;
line-height: 20px;
color: #586069;
border: 1px solid #e1e4e8;
outline-width: 0;
text-decoration: none;
background-color: #f6f8fa;
border-top-left-radius: 3px;
border-bottom-left-radius: 3px;
  }

.toggle-button-2 {
box-sizing: border-box;
display: block;
margin-bottom: 0;
text-decoration: none;
position: relative;
float: left;
padding: 4px 12px;
font-weight: 500;
line-height: 20px;
border: 1px solid #ebeef2;
margin-left: -1px;
color: #fff;
background-color: #243783;
border-color: #243783;
border-top-right-radius: 3px;
border-bottom-right-radius: 3px;
}

.toggle-container {
margin: auto;
padding-top: 10px;
}

li {
list-style: none;
margin: 6px;
display: inline;
}

.search-button {
text-align: center;
font-weight: 500;
border: 1px solid rgba(27,31,35,.2);
border-radius: 8px;
color: #24292e;
background-color: #eff3f6;
background-image: linear-gradient(180deg,#fafbfc,#eff4f6 50%);
padding: 8px 8px;
font-size: 16px;
line-height: 24px;
display: inline-block;
text-align: center;
}

body {
background-color: #f7f8fb;
}

.toggle-header {
line-height: 1.5;
color: #24292e;
box-sizing: border-box;
padding: 16px;
margin: -1px -1px 0;
background-color: #f7f8fb;
border: 1px solid #d1d5da;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
align-items: center;
display: flex;
}

.profile-text-div {
box-sizing: border-box;
width: 100%;
line-height: 1.6;
color: #1c1e21;
margin-top: 0;
font-size: 16px;
font-weight: 400;
margin-bottom: 4px;
}

div.button-container {
  display: center;
  margin: 0 auto;
}

a {
  color: #1c1e21;
}

